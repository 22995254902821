// @flow
import {
    CONTRACT_INVOICE_LIST,
    CONTRACT_INVOICE_LIST_SUCCESS,
    CONTRACT_INVOICE_LIST_FAILED,
    CONTRACT_INVOICE_ADD,
    CONTRACT_INVOICE_ADD_SUCCESS,
    CONTRACT_INVOICE_ADD_FAILED,
    CONTRACT_INVOICE_UPDATE,
CONTRACT_INVOICE_UPDATE_SUCCESS,
CONTRACT_INVOICE_UPDATE_FAILED,
CONTRACT_INVOICE_DELETE,
CONTRACT_INVOICE_DELETE_SUCCESS,
CONTRACT_INVOICE_DELETE_FAILED,

} from './constants';

type ContractInvoiceAction = { type: string, payload: {} | string };

export const getContractInvoiceList = (): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_LIST,
    payload: {},
});

export const getContractInvoiceSuccess = (contractInvoice: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_LIST_SUCCESS,
    payload: contractInvoice,
});

export const getContractInvoiceFailed = (error: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_LIST_FAILED,
    payload: error,
});

export const getContractInvoiceAdd = (data:{}): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_ADD,
    payload: data,
});

export const getContractInvoiceAddSuccess = (contractInvoiceAdd: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_ADD_SUCCESS,
    payload: contractInvoiceAdd,
});

export const getContractInvoiceAddFailed = (error: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_ADD_FAILED,
    payload: error,
});

export const getContractInvoiceUpdate = (data:{}): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_UPDATE,
    payload: data,
});

export const getContractInvoiceUpdateSuccess = (contractInvoiceUpdate: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_UPDATE_SUCCESS,
    payload: contractInvoiceUpdate,
});

export const getContractInvoiceUpdateFailed = (error: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_UPDATE_FAILED,
    payload: error,
});

export const getContractInvoiceDelete = (id): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_DELETE,
    payload: id,
});

export const getContractInvoiceDeleteSuccess = (contractInvoiceDelete: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_DELETE_SUCCESS,
    payload: contractInvoiceDelete,
});

export const getContractInvoiceDeleteFailed = (error: string): ContractInvoiceAction => ({
    type: CONTRACT_INVOICE_DELETE_FAILED,
    payload: error,
});
