// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { CONTRACT_INVOICE_LIST, CONTRACT_INVOICE_ADD, CONTRACT_INVOICE_UPDATE, CONTRACT_INVOICE_DELETE } from './constants';

import {
    getContractInvoiceSuccess,
    getContractInvoiceFailed,
    getContractInvoiceAddSuccess,
    getContractInvoiceAddFailed,
    getContractInvoiceUpdateSuccess,
    getContractInvoiceUpdateFailed,
    getContractInvoiceDeleteSuccess,
    getContractInvoiceDeleteFailed,
} from './actions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoggedInUser } from '../../helpers/authUtils';

const designationAddedSucsess = () => toast.success('Invoice Added Successfully', { transition: Zoom });
const designationDeletedSuccess = () => toast.success('Invoice Deleted Successfully', { transition: Zoom });
const designationUpdated = () => toast.info('Invoice Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* ContractInvoiceList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.contractInvoiceadd,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getContractInvoiceSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getContractInvoiceFailed(message));
    }
}

// ContractInvoice Add

function* ContractInvoiceAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.contractInvoiceadd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        designationAddedSucsess();
        yield put(getContractInvoiceAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getContractInvoiceAddFailed(message));
    }
}

// ContractInvoice Update

function* ContractInvoiceUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.contractInvoiceadd + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        designationUpdated();
        yield put(getContractInvoiceUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getContractInvoiceUpdateFailed(message));
    }
}

// ContractInvoice Delete

function* ContractInvoiceDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.contractInvoiceadd + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        designationDeletedSuccess();
        yield put(getContractInvoiceDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getContractInvoiceDeleteFailed(message));
    }
}

export function* watchContractInvoiceList(): any {
    yield takeEvery(CONTRACT_INVOICE_LIST, ContractInvoiceList);
}
export function* watchContractInvoiceAdd(): any {
    yield takeEvery(CONTRACT_INVOICE_ADD, ContractInvoiceAdd);
}
export function* watchContractInvoiceUpdate(): any {
    yield takeEvery(CONTRACT_INVOICE_UPDATE, ContractInvoiceUpdate);
}
export function* watchContractInvoiceDelete(): any {
    yield takeEvery(CONTRACT_INVOICE_DELETE, ContractInvoiceDelete);
}

function* authSaga(): any {
    yield all([
        fork(watchContractInvoiceList),
        fork(watchContractInvoiceAdd),
        fork(watchContractInvoiceUpdate),
        fork(watchContractInvoiceDelete),
    ]);
}

export default authSaga;
