import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";

// lazy load all the views

// auth

const Login = React.lazy(() => import("../pages/auth/Login"));
const AdminLogin = React.lazy(() => import("../pages/auth/AdminLogin"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const Resetpassword = React.lazy(() => import("../pages/auth/PasswordUpdate"));

//client
const Client = React.lazy(() => import("../pages/Clients"));

//client list
const ClientList = React.lazy(() => import("../pages/ClientList"));
//department
const Department = React.lazy(() => import("../pages/Department"));
//Tasker
const Tasker = React.lazy(() => import("../pages/Tasker"));

//Employee
const Employees = React.lazy(() => import("../pages/Employee"));

//Employee Performance
const EmployeePerformance = React.lazy(() => import("../pages/Employee/EmployeePerformance"));

//ShitSchedule
const ShitSchedule = React.lazy(() => import("../pages/Shiftschedule"));

//Team
const Team = React.lazy(() => import("../pages/Team"));
//Designation//but uses role api
const Designation = React.lazy(() => import("../pages/Designation"));
//Roles//but uses deignation api
const RolesConfig = React.lazy(() => import("../pages/Roles"));
//Settings
const Settings = React.lazy(() => import("../pages/Settings"));
//Requests
const Request = React.lazy(() => import("../pages/Requests"));
//TimeSheet
const TimeSheet = React.lazy(() => import("../pages/Timesheet"));

//Management
const Notification = React.lazy(() =>
  import("../pages/Management/Notification")
);
const Inventory = React.lazy(() => import("../pages/Management/Inventory"));
const Project = React.lazy(() => import("../pages/Management/Project"));

//Interview
const Interview = React.lazy(() => import("../pages/Interview"));
//Announcement
const Announcement = React.lazy(() => import("../pages/Announcement"));
//Attendance
const Attendance = React.lazy(() => import("../pages/Attendance"));
//Shift Record
const Shiftrecord = React.lazy(() => import("../pages/Shiftrecord"));
//DailyReports
const Dailyreport = React.lazy(() => import("../pages/Reports/DailyReport"));
const ReportMonthly= React.lazy(() => import("../pages/Reports/ReportMonthly"));
const Weeklyreport = React.lazy(() => import("../pages/Reports/WeeklyReport"));
const Monthlyreport = React.lazy(() =>
  import("../pages/Reports/MonthlyReport")
);

//Hashbook
const Hashbook = React.lazy(() => import("../pages/Hashbook"));
const HashbookComment = React.lazy(() =>
  import("../pages/Hashbook/HashbookComment")
);

//Leave request
const LeaveRequest = React.lazy(() => import("../pages/Leaverequest/index"));

//Instruction
const Instruction = React.lazy(() => import("../pages/Instruction/index"));

//Worksheet
const Worksheet = React.lazy(() => import("../pages/Worksheet/index"));

//My scores User
const Score = React.lazy(() => import("../pages/Score/index"));

//MyTask
const Mytask = React.lazy(() => import("../pages/my-task/index"));

//User Profile
const UserProfile = React.lazy(() => import("../pages/Userprofile/index"));

//Team Report
const TeamReport = React.lazy(() => import("../pages/Teamreport/index"));
//user tracker
const EmployeeTargetTracker = React.lazy(() => import("../pages/Teamreport/TargetView"));
const EmployeeDailyTargetTrackerDetails = React.lazy(() => import("../pages/Teamreport/DailyTargetDetails"));
const EmployeeMonthlyTargetTrackerDetails = React.lazy(() => import("../pages/Teamreport/MonthlyTargetDetails"));
const EmployeeCustomTargetDetails= React.lazy(() => import("../pages/Teamreport/CustomTargetDetails"));
const EmployeePunchoutListtDetails= React.lazy(() => import("../pages/Teamreport/PunchoutList"));


// dashboard
const EcommerceDashboard = React.lazy(() =>
  import("../pages/dashboards/Ecommerce")
);
const CRMDashboard = React.lazy(() => import("../pages/dashboards/CRM"));
const AnalyticsDashboard = React.lazy(() =>
  import("../pages/dashboards/Analytics")
);
const EmployeeDashboard = React.lazy(() => import("../pages/dashboard"));
// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(() =>
  import("../pages/apps/Projects/Detail/")
);
const ProjectGannt = React.lazy(() => import("../pages/apps/Projects/Gantt/"));
const ProjectForm = React.lazy(() =>
  import("../pages/apps/Projects/ProjectForm")
);
// - ecommece pages
const EcommerceProducts = React.lazy(() =>
  import("../pages/apps/Ecommerce/Products")
);
const ProductDetails = React.lazy(() =>
  import("../pages/apps/Ecommerce/ProductDetails")
);
const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
const OrderDetails = React.lazy(() =>
  import("../pages/apps/Ecommerce/OrderDetails")
);
const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout/"));
const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
// chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// social
const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed/"));
// tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));

// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));




// pages
const Starter = React.lazy(() => import("../pages/Starter"));
const Profile = React.lazy(() => import("../pages/profile"));
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ServerError = React.lazy(() => import("../pages/error/ServerError"));

// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));

// uikit
const Accordions = React.lazy(() => import("../pages/uikit/Accordions"));
const Alerts = React.lazy(() => import("../pages/uikit/Alerts"));
const Badges = React.lazy(() => import("../pages/uikit/Badges"));
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Carousel = React.lazy(() => import("../pages/uikit/Carousel"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Tabs = React.lazy(() => import("../pages/uikit/Tabs"));
const Toasts = React.lazy(() => import("../pages/uikit/Toasts"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const Popovers = React.lazy(() => import("../pages/uikit/Popovers"));
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const Tooltips = React.lazy(() => import("../pages/uikit/Tooltips"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Ratings = React.lazy(() => import("../pages/uikit/Ratings"));

const MDIIcons = React.lazy(() => import("../pages/uikit/MDIIcons"));
const Dripicons = React.lazy(() => import("../pages/uikit/Dripicons"));
const Unicons = React.lazy(() => import("../pages/uikit/Unicons"));
// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));
// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const BriteChart = React.lazy(() => import("../pages/charts/Brite"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));
// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));
// maps
const GoogleMaps = React.lazy(() => import("../pages/GoogleMaps"));

//full report

const FullReport = React.lazy(() =>
  import("../pages/Reports/ReportMonthly/FullReportConfig")
);

//team full report
const TeamFullReport = React.lazy(() =>
  import("../pages/Teamreport/TeamFullReportConfig")
);

//
//hike
const Hike = React.lazy(() => import("../pages/Hike/index"));

//daily report mointhly data
const Monthlydata = React.lazy(() =>
  import("../pages/Reports/MonthlyData")
);

//
const workgraph= React.lazy(() => import("../pages/workgraph/index"));


//search ticket
const SearchTicket = React.lazy(() =>
  import("../pages/SearchTicket/index")
);
//adminhashtOPIC
const AdminHashTopicAdd = React.lazy(() => import("../pages/AdminHashbook/TopicAddIndex"));
//adminhash
const AdminHash = React.lazy(() => import("../pages/AdminHashbook/HashShowConfig"));

//adminhash show
const AdminHashShow = React.lazy(() => import("../pages/AdminHashbook"));

//userhash
const UserHash = React.lazy(() => import("../pages/UserHashbook/TopicAddIndex"));

//userhash show
const UserHashShow = React.lazy(() => import("../pages/UserHashbook/UserHashShow"));
//requwst approval user side

const  UseApprovalRequests = React.lazy(() => import("../pages/Leaveapproval"));

//sing;le topic
const UserHashTopic = React.lazy(() => import("../pages/UserHashbook"));

//payroll 
const PayrollRoute = React.lazy(() => import("../pages/Payroll"));
//payslip
const PayslipRoute = React.lazy(() => import("../pages/Payslip"));
//payslip details
const PayrolldetailsRoute = React.lazy(() => import("../pages/Payrolldetails"));
//admin users
const AdminUserRoute = React.lazy(() => import("../pages/Admin"));

//Onboarding

const OnboardingWelcomeRoute = React.lazy(() => import("../pages/Onboarding/WelcomeConfig"));

const OnboardingInviteRoute = React.lazy(() => import("../pages/Onboarding"));
const OnboardingViewRoute = React.lazy(() => import("../pages/Onboarding/OnboardingView"));

//reamainder
const RemainderViewRoute = React.lazy(() => import("../pages/Remainders"));
//whole user fotm
const EmployeeAllDetails = React.lazy(() => import("../pages/Employee/EmployeeFormConfig"));

const EmployeeSingle = React.lazy(() => import("../pages/Employee/EmployeeSingle"));
const SummaryReport = React.lazy(() => import("../pages/SummaryTarget"));
//daily report edit
const DailyActivityEdit = React.lazy(() => import("../pages/Department/DailyActivityEdit"));

const DailyTargetDetails = React.lazy(() => import("../pages/SummaryTarget/DailyDetailsTarget"));

const MonthlyTargetDetails= React.lazy(() => import("../pages/SummaryTarget/WeeklyDetailsTarget"));
const TicketView= React.lazy(() => import("../pages/SummaryTarget/TicketView"));
const DailyTicketDetails = React.lazy(() => import("../pages/SummaryTarget/DailyTicketDetails"));
const MonthlyTicketDetails= React.lazy(() => import("../pages/SummaryTarget/WeeklyTicketDetails"));
const CustomTargetDetails=React.lazy(() => import("../pages/SummaryTarget/CustomTarget"));
const CustomTicketDetails =React.lazy(() => import("../pages/SummaryTarget/CustomTicket"));

const ViewReports =React.lazy(() => import("../pages/ViewReports"));


//contract Invoice
const ContractInvoice = React.lazy(() => import("../pages/ContractInvoice/index"));


// handle auth and authorization
var admin=window.location.pathname; 

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        if(admin.includes("admin/tasker")){
          return (
            <Redirect
              to={{ pathname: "/admin", state: { from: '/admin/tasker' } }}
            />
          );
        }else{
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );

        }
        // not logged in so redirect to login page with the return url
       
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by role

      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  // component: () => <Redirect to='/dashboard' />,
  component: () =>
    getLoggedInUser().role === "Admin" ? (
      <Redirect to="/admin/dashboard" />
    ) : (
      <Redirect to="/employee/dashboard" />
    ),
  // component: EmployeeDashboard,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: "/admin/dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  icon: "uil-home-alt",
  header: "Navigation",
  component: EmployeeDashboard,
  roles: ["Admin"],
  role_number: [8],
};
const EmployeePerformanceRoutes = {
  path: "/admin/employee/performance/:id",
  name: "Employees",
  route: PrivateRoute,
  icon: "uil-user-check",
  component: EmployeePerformance,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
const EmployeeAllDetailsRoutes = {
  path: "/admin/employee/update/:id",
  name: "Employees",
  route: PrivateRoute,
  component: EmployeeAllDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};

const EmployeeSingleDetailsRoutes = {
  path: "/admin/employee/details/:id",
  name: "Employees",
  route: PrivateRoute,
  component: EmployeeSingle,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};
const AdminUserRoutes = {
  path: "/admin/users",
  name: "Employees",
  route: PrivateRoute,
 
  component: AdminUserRoute,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
///////////////employee
const EmployeeDailyTargetTrackerDetailsRoute = {
  path: "/employee/target-details/daily/:id/:date/:userid",
  route: PrivateRoute,
  component: EmployeeDailyTargetTrackerDetails,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};
const EmployeeMonthlyTargetTrackerDetailsRoute = {
  path: "/employee/target-details/monthly/:id/:date/:userid",
  route: PrivateRoute,
  component: EmployeeMonthlyTargetTrackerDetails,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};


const EmployeeCustomTargetDetailsRoute = {
  path: "/employee/target-details/custom/:id/:startdate/:enddate/:userid",
  route: PrivateRoute,
  component:EmployeeCustomTargetDetails,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};

const EmployeePunchoutListDetailsRoutes = {
  path: "/employee/forced-punchout",
name: "Forced Punch Out",
  route: PrivateRoute,
  icon: "uil-sign-out-alt",
  component: EmployeePunchoutListtDetails,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};



////////////////

const DailyTargetDetailsRoute = {
  path: "/admin/work-reports/target-details/daily/:id/:date/:userid",
  route: PrivateRoute,
  component: DailyTargetDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};
const MonthlyTargetDetailsRoute = {
  path: "/admin/work-reports/target-details/monthly/:id/:date/:userid",
  route: PrivateRoute,
  component:MonthlyTargetDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};
const CustomTargetDetailsRoute = {
  path: "/admin/work-reports/target-details/custom/:id/:startdate/:enddate/:userid",
  route: PrivateRoute,
  component:CustomTargetDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};
const DailyTicketDetailsRoute = {
  path: "/admin/work-reports/ticket-details/daily/:date/:userid",
  route: PrivateRoute,
  component: DailyTicketDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};
const CustomTicketDetailsRoute= {
  path: "/admin/work-reports/ticket-details/custom/:startdate/:enddate/:userid",
  route: PrivateRoute,
  component: CustomTicketDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
  
}
const MonthlyTicketDetailsRoute = {
  path: "/admin/work-reports/ticket-details/monthly/:date/:userid",
  route: PrivateRoute,
  component:MonthlyTicketDetails,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};

const OnboardingViewRoutes= {
  path: "/admin/onboarding/view/:id",
  name: "Onboarding",
  route: PrivateRoute,
 
  component: OnboardingViewRoute,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};

const AdminHashShowRoute = {
  path: "/admin/hashbook/topic/:id",
  name: "HashBook",
  route: PrivateRoute,
  component: AdminHashShow,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
const DailyActivityEditRoutes = {
  path: "/admin/organisation/dailyactivities/:id",
  name: "DailyActivityEdit",
  route: PrivateRoute,
  component: DailyActivityEdit,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};




const ClientRoutes = {
  path: "/admin/clients",
  name: "Clients",
  route: PrivateRoute,
  icon: "uil-user-check",
  component: Client,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};


const ClientListRoutes = {
  path: "/admin/client-lists",
  name: "Clients",
  route: PrivateRoute,
  icon: "uil-user-check",
  component: ClientList,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4,5],
};




const RemindersRoutes ={
  path: "/admin/reminders",
  name: "Reminders",
  route: PrivateRoute,
  icon: "uil-clipboard",
  component: RemainderViewRoute,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};

const UpdatesRoutes = {
  path: "/admin/onboarding",
  name: "Onboarding",
  route: PrivateRoute,
  icon: "uil-history",
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
  children: [
    // {
    //   path: "/admin/updates/clients",
    //   name: "Clients",
    //   route: PrivateRoute,

    //   component: ClientList,
    //   roles: ["Admin"],
    //   role_number: [0, 1, 2, 3, 4, 5],
    // },
    {
      path: "/admin/onboarding/invitation",
      name: "Invitation",
      route: PrivateRoute,

      component: OnboardingInviteRoute,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },


    {
      path: "/admin/onboarding/welcome",
      name: "Welcome",
      route: PrivateRoute,

      component: OnboardingWelcomeRoute,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },


  ],
};

// const OnboardingRoutes = {
//   path: "/admin/onboarding",
//   name: "Onboarding",
//   route: PrivateRoute,
//   icon: "uil-history",
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4, 5],
//   children: [
//     {
//       path: "/admin/onboarding/invitation",
//       name: "Invitation",
//       route: PrivateRoute,

//       component: OnboardingInviteRoute,
//       roles: ["Admin"],
//       role_number: [0, 1, 2, 3, 4, 5],
//     },

//   ],
// };


const EmployeeRoutes = {
  path: "/admin/employees",
  name: "Employees",
  route: PrivateRoute,
  icon: "uil-user-check",
  component: Employees,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
// dashboards
const empdashboardRoutes = {
  path: "/employee/dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  icon: "uil-home-alt",
  header: "Navigation",
  component: EmployeeDashboard,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};

const TimesheetRoutes = {
  path: "/employee/timesheet",
  name: "Timesheet",
  route: PrivateRoute,
  icon: "uil-clock-eight",
  component: TimeSheet,
  role_number:[4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};

const ContractInvoiceRoutes = {
  path: "/employee/contract-invoice",
  name: "Invoice Data",
  route: PrivateRoute,
  icon: "uil-invoice",
  component: ContractInvoice,
  role_number:[3163],
  roles: ["User"],
};



const Worksheetroutes = {
  path: "/employee/worksheet",
  name: "Worksheet",
  route: PrivateRoute,
  icon: "uil-clipboard-notes",
  component: Worksheet,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};


const ViewReportsRoute = {
  path: "/employee/view-report",
  name: "View Report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: ViewReports,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};



const TasksRoutes = {
  path: "/employee/tasks",
  name: "My Tasks",
  route: PrivateRoute,
  icon: "uil-briefcase",
  component: Mytask,
  role_number: [4,115,104,103,102,101,112,114,16,99,119, 11111,22222,31111,3163],
  roles: ["User"],
};

const ScoreRoutes = {
  path: "/employee/scores",
  name: "My Scores",
  route: PrivateRoute,
  icon: "uil-star",
  component: Score,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,31111,3163],
  roles: ["User"],
};
// const RequestRoutes = {
//   path: "/employee/request",
//   name: "Requests",
//   route: PrivateRoute,
//   icon: "uil-bell",
//   component: LeaveRequest,
//   role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222],
//   roles: ["User"],
// };

// const RequestRoutes = {
//   path: "/employee/request",
//   name: "Leave Requests",
//   route: PrivateRoute,
//   icon: "uil-bell",

//   role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222],
//   roles: ["User"],
//   children: [
//     {
//       path: "/employee/request/apply",
//       name: "Requests",
//       route: PrivateRoute,
     
//       component: LeaveRequest,
//      role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222],
//       roles: ["User"],
//     },
//     {
//       path: "/employee/request/approval",
//       name: "Requests Approval",
//       route: PrivateRoute,
     
//       component: UseApprovalRequests,
//       role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222],
//       roles: ["User"],
//     },
  

//   ],
// };

const RequestRoutes = {
  path: "/employee/request",
  name: "Requests",
  route: PrivateRoute,
  icon: "uil-briefcase",
  component: LeaveRequest,
  role_number: [4,115,104,103,102,101,112,114,16,99,119, 11111,22222,31111,3163],
  roles: ["User"],
};
const UseApprovalRequest = {
  path: "/employee/approvals",
  name: "Approvals",
  route: PrivateRoute,
  icon: "uil-document",
  component: UseApprovalRequests,
  role_number: [31111],
  roles: ["User"],
};


// const ShiftupdateRoutes = {
//     path: '/shiftupdates',
//     name: 'Shift Updates',
//     route: PrivateRoute,
//     icon: 'uil-chat-info',
//     component: Starter,
//     roles: ['User'],
// };

const ShiftscheduleRoutes = {
  path: "/employee/shiftschedules",
  name: "Shift Schedule",
  route: PrivateRoute,
  icon: "uil-calender",
  component: ShitSchedule,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};
// const hashbookRoutes = {
//   path: "/admin/hashbook",
//   name: "Hashbook",
//   route: PrivateRoute,
//   icon: "uil-book",
//   component: Hashbook,
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4],
// };

const adminhashbookRoutes = {
  path: "/admin/hashbook/topics",
  name: "HashBook",
  route: PrivateRoute,
  icon: "uil-book",
  component: AdminHashTopicAdd,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
const adminhashbookviewRoutes = {
  path: "/admin/hashbook/subject/:id",
  name: "HashBook",
  route: PrivateRoute,
  icon: "uil-book",
  component: AdminHash,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};


const employeehashbookRoutes = {
  path: "/employee/hashbook/topics",
  name: "HashBook",
  route: PrivateRoute,
  icon: "uil-book",
  component: UserHash,
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
  roles: ["User"],
};
const EmployeeTopicHashShowRoute = {
  path: "/employee/hashbook/topic/:id",
  name: "Hashbook",
  route: PrivateRoute,
  component: UserHashTopic,
  roles: ["User"],
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
};

const hashbookuserViewRoutes = {
  path: "/employee/hashbook/subject/:id",
  name: "HashBook View",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: UserHashShow,
  roles: ["User"],
  role_number: [4,115,104,103,102,101,112,114,16,99,119,11111,22222,31111,3163],
};
// const employeehashbookcommentRoutes = {
//   path: "/employee/hashcomment/:id",
//   name: "Hashbook Comment",
//   route: PrivateRoute,
//   icon: "uil-comment-dots",
//   component: HashbookComment,
//   roles: ["User","Admin"],
// };

const userProfile = {
  path: "/admin/userprofile",
  name: "User Profile",
  route: PrivateRoute,
  icon: "uil-chat-bubble-user",
  component: UserProfile,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
const hikeRoutes = {
  path: "/admin/hikes",
  name: "Hikes",
  route: PrivateRoute,
  icon: "uil-briefcase",
  component: Hike,
  role_number: [0, 1, 2, 3, 4,5],
  roles: ["Admin"],
};

const employeeuserProfile = {
  path: "/employee/userprofile",
  name: "User Profile",
  route: PrivateRoute,
  icon: "uil-chat-bubble-user",
  component: UserProfile,
  role_number: [99,4,115,104,103,102,101,112,114,16,119,11111,22222,31111,3163],
  roles: ["User"],
};

const InstructionRoutes = {
  path: "/employee/instructions",
  name: "Instructions",
  route: PrivateRoute,
  icon: "uil-info-circle",
  component: Instruction,
  role_number: [99,4,115,104,103,102,101,112,114,16,99,119,31111],
  roles: ["User"],
};

const TeamReportRoutes = {
  path: "/employee/team-reports",
name: "Team Reports",
  route: PrivateRoute,
  icon: "uil-chat-bubble-user",
  component: TeamReport,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};

//employee target tracker
const EmployeeTargetTrackerRoutes = {
  path: "/employee/trackers",
name: "Trackers",
  route: PrivateRoute,
  icon: "uil-invoice",
  component: EmployeeTargetTracker,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};

//SearchTicket
const SearchTicketRoutes = {
  path: "/employee/search-tickets",
name: "Search Ticket",
  route: PrivateRoute,
  icon: "uil-search",
  component: SearchTicket,
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
  roles: ["User"],
};



const TaskerRoutes = {
  path: "/admin/tasker",
  name: "Tasker",
  route: PrivateRoute,
  icon: "uil-clipboard-alt",
  component: Tasker,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};

const DepartmentRoutes = {
  path: "/admin/department",
  name: "Departments",
  route: PrivateRoute,
  icon: "uil-building",
  component: Department,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};

const TeamRoutes = {
  path: "/admin/teams",
  name: "Teams",
  route: PrivateRoute,
  icon: "uil-users-alt",
  component: Team,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 5],
};

// const AttendanceRoutes = {
//   path: "/admin/attendance",
//   name: "Attendance",
//   route: PrivateRoute,
//   icon: "uil-schedule",
//   component: Attendance,
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4, 5],
// };
const AdminRequestRoutes = {
  path: "/admin/requests",
  name: "Requests",
  route: PrivateRoute,
  icon: "uil-bell",
  component: Request,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};
const SettingRoutes = {
  path: "/admin/settings",
  name: "Settings",
  route: PrivateRoute,
  icon: "uil-cog",
  component: Settings,
  roles: ["Admin"],
  role_number: [33],
};
// const ShiftRecordRoutes = {
//   path: "/admin/shiftrecords",
//   name: "Shift Records",
//   route: PrivateRoute,
//   icon: "uil-database",
//   component: Shiftrecord,
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3],
// };
const InterviewRoute = {
  path: "/admin/offered-candidates",
  name: "Offered Candidates",
  route: PrivateRoute,
  icon: "uil-chart-pie-alt",
  component: Interview,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};
const AnnouncementRoute = {
  path: "/admin/announcements",
  name: "Announcements",
  route: PrivateRoute,
  icon: "uil-microphone",
  component: Announcement,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3],
};
const DesignationRoute = {
  path: "/admin/designation",
  name: "Designation",
  route: PrivateRoute,
  icon: "uil-suitcase-alt",
  component: Designation,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
};
const fullreportRoutes = {

  path: "/admin/daily-report",
  name: "Daily Work Report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: ReportMonthly,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};
const fullreport1Routes = {
  path: "/admin/daily-reports/:id",
  name: "full report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: FullReport,
  roles: ["Admin"],
  role_number: [8],
};




const PayrolldetailsRoutes = {
  path: "/admin/payroll-details/:id",
  name: "Payroll Details",
  route: PrivateRoute,

  component: PayrolldetailsRoute,
  roles: ["Admin"],
  role_number: [8],

};


const fullreportteamRoutes = {
  path: "/employee/team-report/:id",
  name: "full report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: TeamFullReport,
  roles: ["User"],
  role_number: [8],
};

const workgraphRoutes = {
  path: "/employee/workgraph",
  name: "Work Report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: workgraph,
  roles: ["User"],
  role_number: [4,115,104,103,102,101,112,114,16,119,22222,31111],
};


const monthlydataRoutes = {
  path: "/admin/monthly-report",
  name: "Monthly Report",
  route: PrivateRoute,
  icon: "uil-comment-dots",
  component: Monthlydata,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
};



const DailyReportCheckDetailsRoute ={
  path: "/admin/daily-reports/check",
  route: PrivateRoute,
  component: Dailyreport,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],


}
// const ReportRoutes = {
//   path: "/admin/reports",
//   name: "Reports",
//   route: PrivateRoute,
//   icon: "uil-invoice",
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4, 5],
//   children: [
//     {
//       path: "/admin/reports/daily",
//       name: "Daily Reports",
//       route: PrivateRoute,

//       component: Dailyreport,
//       roles: ["Admin"],
//       role_number: [0, 1, 2, 3, 4, 5],
//     },
//     {
//       path: "/admin/reports/weekly",
//       name: "Weekly Reports",
//       route: PrivateRoute,

//       component: Weeklyreport,
//       roles: ["Admin"],
//       role_number: [0, 1, 2, 3, 4, 5],
//     },
//     {
//       path: "/admin/reports/monthly",
//       name: "Monthly Reports",
//       route: PrivateRoute,

//       component: Monthlyreport,
//       roles: ["Admin"],
//       role_number: [0, 1, 2, 3, 4, 5],
//     },
// {

//   path: "/admin/reports/attendance",
//   name: "Attendance",
//   route: PrivateRoute,
//   component: Attendance,
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4, 5],


// },

// {
//   path: "/admin/shiftrecords",
//   name: "Shift Records",
//   route: PrivateRoute,
//   component: Shiftrecord,
//   roles: ["Admin"],
//   role_number: [0, 1, 2, 3, 4, 5],
// }
 

//   ],
// };


const PayRoutes = {
  path: "/admin/payroll",
  name: "Payroll",
  route: PrivateRoute,
  icon: "uil-receipt-alt",
  roles: ["Admin"],
  role_number: [1,3],
  children: [
    {
      path: "/admin/payroll/add-salary",
      name: "Payslips",
      route: PrivateRoute,

      component: PayrollRoute,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    // {
    //   path: "/admin/payroll/payslip-download",
    //   name: "Payslip Download",
    //   route: PrivateRoute,

    //   component: PayslipRoute,
    //   roles: ["Admin"],
    //   role_number: [0, 1, 2, 3, 4, 5],
    // },
    
  ],
};
//work reports

const workRoutes = {
  path: "/admin/work-reports",
  name: "Reports",
  route: PrivateRoute,
  icon: "uil-invoice",
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
  children: [
    {
      path: "/admin/work-reports/target-trackers",
      name: "Trackers",
      route: PrivateRoute,
    //  icon: "uil-comment-dots",
      component: SummaryReport,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    {
      path: "/admin/work-reports/ticket-trackers",
      name: "Tickets",
      route: PrivateRoute,
    //  icon: "uil-comment-dots",
      component: TicketView,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    
    {
      path: "/admin/work-reports/daily-report",
      name: "Daily Work Report",
      route: PrivateRoute,
    //  icon: "uil-comment-dots",
      component: ReportMonthly,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    {
      path: "/admin/work-reports/monthly-report",
      name: "Monthly Report",
      route: PrivateRoute,
    //  icon: "uil-comment-dots",
      component: Monthlydata,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    {

      path: "/admin/reports/attendance",
      name: "Attendance",
      route: PrivateRoute,
      component: Attendance,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    
    
    },
    
    {
      path: "/admin/shiftrecords",
      name: "Shift Records",
      route: PrivateRoute,
      component: Shiftrecord,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    }
    
  ],
};
//
const organisationRoutes = {
 
  name: "Organisation",
  route: PrivateRoute,
  icon: "uil-users-alt",
  path: "/admin/organisation",
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
  children: [
    {
      path: "/admin/organisation/department",
      name: "Departments",
      route: PrivateRoute,

      component: Department,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },
    {
      path: "/admin/organisation/teams",
  name: "Teams",
  route: PrivateRoute,
  
  component: Team,
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4, 5],
},
    {
      path: "/admin/organisation/roles",
      name: "Roles",
      route: PrivateRoute,
     
      component: Designation,
      roles: ["Admin"],
      role_number: [1],
    },
    {
      path: "/admin/organisation/designation",
      name: "Designation",
      route: PrivateRoute,
     
      component: RolesConfig,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4, 5],
    },


    {
      path: "/admin/organisation/clients",
      name: "Clients",
      route: PrivateRoute,
     
      component: Client,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4,5],
    },
    

  ],
};
const ManagementRoutes = {
  path: "/admin/management",
  name: "Management",
  route: PrivateRoute,
  icon: "uil-sitemap",
  roles: ["Admin"],
  role_number: [0, 1, 2, 3, 4],
  children: [
    {
      path: "/admin/management/inventory",
      name: "Inventory Mangement",
      route: PrivateRoute,
      component: Inventory,
      roles: ["Admin"],
      role_number: [0, 2, 3],
    },
    {
      path: "/admin/management/project",
      name: "Project Management",
      route: PrivateRoute,
      component: Project,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3, 4],
    },
    {
      path: "/admin/management/notification",
      name: "Notification Management",
      route: PrivateRoute,
      component: Notification,
      roles: ["Admin"],
      role_number: [0, 1, 2, 3],
    },
  ],
};

const appRoutes = [
  EmployeeRoutes,
  TaskerRoutes,
  // OnboardingRoutes,
  RemindersRoutes,
  organisationRoutes,

  workRoutes,
  
  // DepartmentRoutes,
  // TeamRoutes,
  // DesignationRoute,
  PayRoutes,
  // AttendanceRoutes,
  AdminRequestRoutes,
  // ReportRoutes,
  ManagementRoutes,
  SettingRoutes,

  // ShiftRecordRoutes,
  InterviewRoute,
  TimesheetRoutes,
  Worksheetroutes,
  // ViewReportsRoute,
  TasksRoutes,
  ScoreRoutes,
  RequestRoutes,
  UseApprovalRequest,
  // ShiftupdateRoutes
  PayrolldetailsRoutes,
  ShiftscheduleRoutes,
  adminhashbookRoutes,
  employeehashbookRoutes,
  // InstructionRoutes,
  fullreport1Routes,
  TeamReportRoutes,
  EmployeeTargetTrackerRoutes,
  EmployeePunchoutListDetailsRoutes,
  fullreportteamRoutes,
  UpdatesRoutes,

  hikeRoutes,
  AnnouncementRoute,

  workgraphRoutes,
  SearchTicketRoutes,
  ContractInvoiceRoutes
];

const getUpdatedRoutes = (appRoutes) => {
  let roleRoutes = [];

  if (getLoggedInUser() && getLoggedInUser().role) {
    const getrole = getLoggedInUser().role;
    roleRoutes = appRoutes.filter((t) => t.roles.includes(getrole));
  }

  return roleRoutes;
};
let roleRoutes = getUpdatedRoutes(appRoutes);

// auth
const loginRoute = {
  path: "/login",
  name: "Login",
  component: Login,
  route: Route,
};

const AdminloginRoute = {
  path: "/admin",
  name: "Login",
  component: AdminLogin,
  route: Route,
};

const logoutRoute = {
  path: "/logout",
  name: "Logout",
  component: Logout,
  route: Route,
};

const forgotPasswordRoute = {
  path: "/forget-password",
  name: "Forget Password",
  component: ForgetPassword,
  route: Route,
};
const passwordReset = {
  path: "/password-reset/:id",
  name: "Reset Password",
  component: Resetpassword,
  route: Route,
};
const authRoutes = [
  loginRoute,
  AdminloginRoute,
  logoutRoute,
  forgotPasswordRoute,
  passwordReset,
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [
  rootRoute,
  dashboardRoutes,
  OnboardingViewRoutes,
  EmployeePerformanceRoutes,
  EmployeeAllDetailsRoutes,
  EmployeeSingleDetailsRoutes,
  AdminHashShowRoute,
  DailyActivityEditRoutes,
  DailyTargetDetailsRoute,
  MonthlyTargetDetailsRoute,
  CustomTargetDetailsRoute,
  DailyTicketDetailsRoute,
  MonthlyTicketDetailsRoute,
  DailyReportCheckDetailsRoute,
  CustomTicketDetailsRoute,
  EmployeeTopicHashShowRoute,
  hashbookuserViewRoutes,
  adminhashbookviewRoutes,
  EmployeeDailyTargetTrackerDetailsRoute,
  EmployeeMonthlyTargetTrackerDetailsRoute,
  EmployeeCustomTargetDetailsRoute,
  // UseApprovalRequests,
  empdashboardRoutes,
  AdminUserRoutes,

 
  ...appRoutes,
  ...authRoutes,

  // employeehashbookcommentRoutes,
  employeeuserProfile,
  userProfile,
];
const loginRoutes = [
  rootRoute,
  dashboardRoutes,
  empdashboardRoutes,
  ...authRoutes,
];

const authProtectedRoutes = [dashboardRoutes, empdashboardRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export {
  allRoutes,
  authProtectedRoutes,
  allFlattenRoutes,
  getUpdatedRoutes,
  appRoutes,
  loginRoutes,
};
