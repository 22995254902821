// @flow
import {
    CONTRACT_INVOICE_LIST,
    CONTRACT_INVOICE_LIST_SUCCESS,
    CONTRACT_INVOICE_LIST_FAILED,
    CONTRACT_INVOICE_ADD,
    CONTRACT_INVOICE_ADD_SUCCESS,
    CONTRACT_INVOICE_ADD_FAILED,
    CONTRACT_INVOICE_UPDATE,
CONTRACT_INVOICE_UPDATE_SUCCESS,
CONTRACT_INVOICE_UPDATE_FAILED,
CONTRACT_INVOICE_DELETE,
CONTRACT_INVOICE_DELETE_SUCCESS,
CONTRACT_INVOICE_DELETE_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type ContractInvoiceAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const ContractInvoice = (state: State = INIT_STATE, action: ContractInvoiceAction) => {
    switch (action.type) {
        case CONTRACT_INVOICE_LIST:
            return { ...state, listloading: true };
        case CONTRACT_INVOICE_LIST_SUCCESS:
            return { ...state, contractInvoice: action.payload, listloading: false, error: null };
        case CONTRACT_INVOICE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CONTRACT_INVOICE_ADD:
            return { ...state, loading: true };
        case CONTRACT_INVOICE_ADD_SUCCESS:
            return { ...state, contractInvoiceAdd: action.payload, loading: false, error: null };
        case CONTRACT_INVOICE_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CONTRACT_INVOICE_UPDATE:
            return { ...state, loading: true };
        case CONTRACT_INVOICE_UPDATE_SUCCESS:
            return { ...state, contractInvoiceUpdate: action.payload, loading: false, error: null };
        case CONTRACT_INVOICE_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CONTRACT_INVOICE_DELETE:
            return { ...state, loading: true };
        case CONTRACT_INVOICE_DELETE_SUCCESS:
            return { ...state, contractInvoiceDelete: action.payload, loading: false, error: null };
        case CONTRACT_INVOICE_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default ContractInvoice;
