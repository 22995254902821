
export const  CONTRACT_INVOICE_LIST= 'CONTRACT_INVOICE_LIST';
export const  CONTRACT_INVOICE_LIST_SUCCESS= 'CONTRACT_INVOICE_LIST_SUCCESS';
export const  CONTRACT_INVOICE_LIST_FAILED= 'CONTRACT_INVOICE_LIST_FAILED';
export const  CONTRACT_INVOICE_ADD= 'CONTRACT_INVOICE_ADD';
export const  CONTRACT_INVOICE_ADD_SUCCESS= 'CONTRACT_INVOICE_ADD_SUCCESS';
export const  CONTRACT_INVOICE_ADD_FAILED= 'CONTRACT_INVOICE_ADD_FAILED';



export const  CONTRACT_INVOICE_UPDATE= 'CONTRACT_INVOICE_UPDATE';
export const  CONTRACT_INVOICE_UPDATE_SUCCESS= 'CONTRACT_INVOICE_UPDATE_SUCCESS';
export const  CONTRACT_INVOICE_UPDATE_FAILED= 'CONTRACT_INVOICE_UPDATE_FAILED';
export const  CONTRACT_INVOICE_DELETE= 'CONTRACT_INVOICE_DELETE';
export const  CONTRACT_INVOICE_DELETE_SUCCESS= 'CONTRACT_INVOICE_DELETE_SUCCESS';
export const  CONTRACT_INVOICE_DELETE_FAILED= 'CONTRACT_INVOICE_DELETE_FAILED';
